<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <free-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="true"
      @on-submit="createFreeCreate"
    >
    </free-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import LabelService from '@admin/services/LabelService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import FreeForm from './_From'

export default {
  name: 'FreeCreate',
  components: { PlaceholderForm, FreeForm },
  data () {
    return {
      pageTitle: '编辑标签',
      model: null
    }
  },
  async created () {
    const { data } = await flatry(LabelService.orderEdit(this.$router.currentRoute.query.id))

    if (data) {
      this.model = data.data.model
      if (this.model.parent_id !== 0) {
        this.model.classify = data.data.classify
        this.model.classify_id = this.model.parent_id
      }
    }
  },
  methods: {
    async createFreeCreate (model, success, callback) {
      const { data } = await flatry(LabelService.orderEdit(model.id, model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/label/order/update',
          query: { id: data.data }
        })

        success()
      }

      callback()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
